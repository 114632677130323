import {
  addDays,
  addHours,
  addWeeks,
  differenceInDays,
  endOfDay,
  startOfDay,
  subDays,
  subHours,
  subWeeks,
} from "date-fns";
import {
  getFirstDayOfMonthlyCalendarJSDate,
  getFirstDayOfWeekJsDate,
  getLastDayOfMonthlyCalendarJSDate,
  getLastDayOfWeekJsDate,
  isDayView,
  isSameOrAfterMinute,
  isSameOrBeforeMinute,
  isValidJSDate,
} from "../services/commonUsefulFunctions";
import { BACKEND_MONTH } from "../services/globalVariables";
import { PAID_ACCESS_REQUIRED } from "./vimcalVariables";
import { is4DayView, isRolling7DayView } from "./stateManagementFunctions";
import { isMonthlyView } from "../services/appFunctions";
import { lowerCaseAndTrimStringWithGuard } from "./stringFunctions";
import { OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT } from "./outlookFunctions";
import { isEmptyArrayOrFalsey } from "../services/typeGuards";

// show events as they come in
export function isPaidAccessRequiredError(error) {
  return error === PAID_ACCESS_REQUIRED;
}

export function isQuotaExceededError(error) {
  if (!error) {
    return false;
  }
  return lowerCaseAndTrimStringWithGuard(error).includes("quota exceeded");
}

// passes back entire object in one go with timeMin and timeMax
// since we're not deleting, this allows us to sync 12 hours out instead of 24 hours
export function getEntireCurrentSyncWindowForPreviewEvents({
  selectedDay,
  weekStart,
  selectedCalendarView,
}) {
  if (is4DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(selectedDay);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 3));
    return addBufferToWindowForPreviewEvents({
      timeMin: initialWindowStart,
      timeMax: initialWindowEnd,
    });
  }

  if (isRolling7DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(selectedDay);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 6));
    return addBufferToWindowForPreviewEvents({
      timeMin: initialWindowStart,
      timeMax: initialWindowEnd,
    });
  }

  if (isMonthlyView(selectedCalendarView)) {
    const initialWindowStart = getFirstDayOfMonthlyCalendarJSDate(selectedDay, weekStart);
    const initialWindowEnd = getLastDayOfMonthlyCalendarJSDate(selectedDay, weekStart);
    return addBufferToWindowForPreviewEvents({
      timeMin: initialWindowStart,
      timeMax: initialWindowEnd,
    });
  }
  if (isDayView(selectedCalendarView)) {
    return addBufferToWindowForPreviewEvents({
      timeMin: startOfDay(selectedDay),
      timeMax: endOfDay(selectedDay),
    });
  }

  // else -> sync the week
  const weekStartOfSelectedDay = getFirstDayOfWeekJsDate(selectedDay, weekStart);
  return addBufferToWindowForPreviewEvents({
    timeMin: weekStartOfSelectedDay,
    timeMax: endOfDay(addDays(weekStartOfSelectedDay, 6)),
  });
}

// passes back array
export function getCurrentSyncWindow({
  selectedDay,
  weekStart,
  selectedCalendarView,
}) {
  const today = new Date();
  if (is4DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(today);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 3));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: initialWindowStart,
      timeMax: initialWindowEnd,
    });
    return [
      {
        timeMin,
        timeMax,
      },
    ];
  }
  if (isRolling7DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(today);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 6));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: initialWindowStart,
      timeMax: initialWindowEnd,
    });
    return [
      {
        timeMin,
        timeMax,
      },
    ];
  }

  const weekStartOfSelectedDay = getFirstDayOfWeekJsDate(today, weekStart);
  const { timeMin: todayDayTimeMin, timeMax: todayDayTimeMax } =
    addBufferToWindow({
      timeMin: weekStartOfSelectedDay,
      timeMax: endOfDay(addDays(weekStartOfSelectedDay, 6)),
    });
  const selectedDayWindow = {
    timeMin: todayDayTimeMin,
    timeMax: todayDayTimeMax,
  };
  if (
    isSameOrAfterMinute(today, selectedDayWindow.timeMin) &&
    isSameOrBeforeMinute(today, selectedDayWindow.timeMax)
  ) {
    // selecte day view is the current week
    return [selectedDayWindow];
  }

  const startOfCurrentWeek = getFirstDayOfWeekJsDate(
    getSelectedDayWithBackup(selectedDay),
    weekStart
  );
  const { timeMin: selectedDayTimeMin, timeMax: selectedDayTimeMax } =
    addBufferToWindow({
      timeMin: startOfCurrentWeek,
      timeMax: endOfDay(addDays(startOfCurrentWeek, 6)),
    });
  return [
    {
      timeMin: selectedDayTimeMin,
      timeMax: selectedDayTimeMax,
    },
    ...selectedDayWindow,
  ];
}

export function getSelectedDayWithBackup(selectedDay) {
  // selected day sometimes is null because of race conditions
  try {
    return selectedDay && isValidJSDate(selectedDay) ? selectedDay : new Date();
  } catch (e) {
    return new Date();
  }
}

// no point in adding buffer since this is in background
export function getBackgroundSyncTimes({ weekStart }) {
  const today = new Date();
  const startOfCurrentWeek = getFirstDayOfWeekJsDate(today, weekStart);
  const forwardSyncWindows = [
    {
      timeMin: addDays(startOfCurrentWeek, 7),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 13)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 14),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 20)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 21),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 27)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 28),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 34)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 35),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 41)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 42),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 48)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 49),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 55)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 56),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 62)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 63),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 69)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 70),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 76)),
    },
  ];

  const backwardSyncWindows = [
    {
      timeMin: subDays(startOfCurrentWeek, 7),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 1)),
    },
    {
      timeMin: subDays(startOfCurrentWeek, 14),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 8)),
    },
    {
      timeMin: subDays(startOfCurrentWeek, 21),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 15)),
    },
    {
      timeMin: subDays(startOfCurrentWeek, 28),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 22)),
    },
    {
      timeMin: subDays(startOfCurrentWeek, 35),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 29)),
    },
    {
      timeMin: subDays(startOfCurrentWeek, 42),
      timeMax: endOfDay(subDays(startOfCurrentWeek, 36)),
    },
  ];

  return {
    forwardSyncWindows,
    backwardSyncWindows,
    allSyncWindows: [...forwardSyncWindows, ...backwardSyncWindows],
  };
}

export function getOutlookChoppedSyncWindows({
  selectedCalendarView,
  selectedDay,
  weekStart,
  userCalendarIDsToFetch
}) {
  const checkedDate = getSelectedDayWithBackup(selectedDay);
  if (isDayView(selectedCalendarView) && userCalendarIDsToFetch?.length >= OUTLOOK_ACTIVE_CALENDARS_THROTTLE_AMOUNT) {
    // if user is syncing a ton of calendars
    return [
      getDayViewSyncWindow({ selectedDay }),
    ];
  }

  if (selectedCalendarView === BACKEND_MONTH) {
    const firstDayOfMonth = getFirstDayOfMonthlyCalendarJSDate(
      checkedDate,
      weekStart,
    );
    const syncWindows = [
      {
        timeMin: subDays(firstDayOfMonth, 1),
        timeMax: endOfDay(addDays(firstDayOfMonth, 6)),
      }, // first day of month
      {
        timeMin: addDays(firstDayOfMonth, 7),
        timeMax: endOfDay(addDays(firstDayOfMonth, 13)),
      },
      {
        timeMin: addDays(firstDayOfMonth, 14),
        timeMax: endOfDay(addDays(firstDayOfMonth, 20)),
      },
      {
        timeMin: addDays(firstDayOfMonth, 21),
        timeMax: endOfDay(addDays(firstDayOfMonth, 27)),
      },
      {
        timeMin: addDays(firstDayOfMonth, 28),
        timeMax: endOfDay(addDays(firstDayOfMonth, 34)),
      },
      {
        timeMin: addDays(firstDayOfMonth, 35),
        timeMax: addDays(endOfDay(addDays(firstDayOfMonth, 41)), 1),
      },
    ];
    return syncWindows;
  }
  if (is4DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(checkedDate);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 3));
    const syncWindows = [
      {
        timeMin: initialWindowStart,
        timeMax: initialWindowEnd,
      },
      {
        timeMin: addDays(initialWindowStart, 4),
        timeMax: addDays(initialWindowEnd, 4),
      },
      // {
      //   timeMin: addDays(initialWindowStart, 8),
      //   timeMax: addDays(initialWindowEnd, 4 * 2),
      // },
      // {
      //   timeMin: subDays(initialWindowStart, 4),
      //   timeMax: subDays(initialWindowEnd, 4),
      // },
    ];
    return syncWindows.map((syncWindow) => {
      return addBufferToWindow({
        timeMin: syncWindow.timeMin,
        timeMax: syncWindow.timeMax,
      });
    });
  }

  if (isRolling7DayView(selectedCalendarView)) {
    const initialWindowStart = startOfDay(checkedDate);
    const initialWindowEnd = endOfDay(addDays(initialWindowStart, 6));
    const syncWindows = [
      {
        timeMin: initialWindowStart,
        timeMax: initialWindowEnd,
      },
      {
        timeMin: addDays(initialWindowStart, 7),
        timeMax: addDays(initialWindowEnd, 7),
      },
      // {
      //   timeMin: addDays(initialWindowStart, 14),
      //   timeMax: addDays(initialWindowEnd, 7 * 2),
      // },
      // {
      //   timeMin: subDays(initialWindowStart, 7),
      //   timeMax: subDays(initialWindowEnd, 7),
      // },
    ];
    return syncWindows.map((syncWindow) => {
      return addBufferToWindow({
        timeMin: syncWindow.timeMin,
        timeMax: syncWindow.timeMax,
      });
    });
  }

  const startOfCurrentWeek = getFirstDayOfWeekJsDate(checkedDate, weekStart);
  // outlook rate limits to 4 at a time
  const syncWindows = [
    {
      timeMin: startOfCurrentWeek,
      timeMax: endOfDay(addDays(startOfCurrentWeek, 6)),
    },
    {
      timeMin: addDays(startOfCurrentWeek, 7),
      timeMax: endOfDay(addDays(startOfCurrentWeek, 13)),
    },
    // {
    //   timeMin: addDays(startOfCurrentWeek, 14),
    //   timeMax: endOfDay(addDays(startOfCurrentWeek, 20)),
    // },
    // {
    //   timeMin: addDays(startOfCurrentWeek, 21),
    //   timeMax: endOfDay(addDays(startOfCurrentWeek, 27)),
    // },
    // {
    //   timeMin: addDays(startOfCurrentWeek, 28),
    //   timeMax: endOfDay(addDays(startOfCurrentWeek, 34)),
    // },
    // {
    //   timeMin: subDays(startOfCurrentWeek, 7),
    //   timeMax: endOfDay(subDays(startOfCurrentWeek, 1)),
    // },
    // {
    //   timeMin: subDays(startOfCurrentWeek, 14),
    //   timeMax: endOfDay(subDays(startOfCurrentWeek, 8)),
    // },
  ];
  return syncWindows.map((syncWindow) => {
    return addBufferToWindow({
      timeMin: syncWindow.timeMin,
      timeMax: syncWindow.timeMax,
    });
  })
}

export function getResponseLastSyncAtUTC(response) {
  return response?.last_synced_at_utc;
}

export function getFetchWindowStartAndEnd({
  windowJSDate,
  weekStart,
  selectedCalendarView,
}) {
  if (is4DayView(selectedCalendarView)) {
    const weeksBackward = startOfDay(windowJSDate);
    const weeksForward = endOfDay(addDays(windowJSDate, 3));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: weeksBackward,
      timeMax: weeksForward,
    });
    return {
      weeksBackward: timeMin,
      weeksForward: timeMax,
    };
  }
  if (isRolling7DayView(selectedCalendarView)) {
    const weeksBackward = startOfDay(windowJSDate);
    const weeksForward = endOfDay(addDays(windowJSDate, 6));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: weeksBackward,
      timeMax: weeksForward,
    });
    return {
      weeksBackward: timeMin,
      weeksForward: timeMax,
    };
  }
  const weeksBackward = getFirstDayOfWeekJsDate(windowJSDate, weekStart);
  const weeksForward = getLastDayOfWeekJsDate(windowJSDate, weekStart);
  const { timeMin, timeMax } = addBufferToWindow({
    timeMin: weeksBackward,
    timeMax: weeksForward,
  });
  return {
    weeksBackward: timeMin,
    weeksForward: timeMax,
  };
}

export function getInitialSyncWindowForGoogle({
  windowJSDate,
  weekStart,
  selectedCalendarView,
}) {
  if (is4DayView(selectedCalendarView)) {
    const weeksBackward = startOfDay(subDays(windowJSDate, 8));
    const weeksForward = startOfDay(addDays(windowJSDate, 7));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: weeksBackward,
      timeMax: weeksForward,
    });
    return {
      weeksBackward: timeMin,
      weeksForward: timeMax,
    };
  }
  if (isRolling7DayView(selectedCalendarView)) {
    const weeksBackward = startOfDay(subDays(windowJSDate, 7));
    const weeksForward = startOfDay(addDays(windowJSDate, 13));
    const { timeMin, timeMax } = addBufferToWindow({
      timeMin: weeksBackward,
      timeMax: weeksForward,
    });
    return {
      weeksBackward: timeMin,
      weeksForward: timeMax,
    };
  }
  const weeksBackward = getFirstDayOfWeekJsDate(
    subWeeks(windowJSDate, 1),
    weekStart
  );
  const weeksForward = getLastDayOfWeekJsDate(
    addWeeks(windowJSDate, 1),
    weekStart
  );
  const { timeMin, timeMax } = addBufferToWindow({
    timeMin: weeksBackward,
    timeMax: weeksForward,
  });
  return {
    weeksBackward: timeMin,
    weeksForward: timeMax,
  };
}

export function addBufferToWindowForPreviewEvents({
  timeMin,
  timeMax,
}) {
  // 12 hours is the furthest away
  return {
    timeMin: subHours(timeMin, 12),
    timeMax: addHours(timeMax, 12),
  };
}

export function addBufferToWindow({
  timeMin,
  timeMax,
  addExtraBuffer, // if fetching for google, we can extend the buffer a few days out
}) {
  if (addExtraBuffer && differenceInDays(timeMax, timeMin) < 28) { // if it's month, no point in expanded buffer
    return {
      timeMin: subDays(timeMin, 7),
      timeMax: addDays(timeMax, 7),
    };
  }
  return {
    timeMin: subDays(timeMin, 1),
    timeMax: addDays(timeMax, 1),
  };
}

export function shouldSkipFetchEventsResponse(response) {
  return isEmptyArrayOrFalsey(response?.events);
}

// need to account for day before and after incase there's time travel
export function getDayViewSyncWindow({ selectedDay }) {
  return {
    timeMin: startOfDay(subDays(selectedDay, 1)),
    timeMax: endOfDay(addDays(selectedDay, 1)),
  };
}

export function getDayViewSyncWindowForOutlookPreviewEvents({ selectedDay }) {
  return {
    timeMin: subHours(startOfDay(selectedDay), 12),
    timeMax: addHours(endOfDay(selectedDay), 12),
  };
}
